// WaitlistModal.js
import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { addToWaitlistService } from '../services/waitlistService'; 
import { toast } from 'react-toastify';

const WaitlistModal = ({ show, handleClose, storeHandle, product }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mainImage, setMainImage] = useState('default-image-url');

  useEffect(() => {
    if (product) {
      setMainImage(product.images?.[0]?.url || 'default-image-url');
    }
  }, [product]);

  const handleWaitlistSignup = async () => {
    setLoading(true);
    setError(false);
  
    try {
      const response = await addToWaitlistService(storeHandle, product._id, email);
      const responseData = await response.json(); // Parse the JSON response from the backend
  
      if (response.ok) {
        // Display the backend message in the success toast
        setTimeout(() => {
          toast.success(responseData.message || 'You have been added to the waitlist.');
          handleClose(); // Close the modal on success
          setLoading(false);
        }, 1000);
      } else {
        // Throw an error with the backend message
        throw new Error(responseData.message || 'Failed to join the waitlist');
      }
    } catch (error) {
      console.error('Error adding to waitlist:', error);
      // Display the backend error message (if available) or a default error message
      toast.error(error.message || 'An error occurred. Please try again.');
      setError(true);
      setTimeout(() => {
        setError(false); // Revert error state after 3 seconds
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title></Modal.Title>
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="email">
            <Form.Label>{product ? product.name : 'Product Waitlist'}</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
      <button 
          type="button" 
          className={`sunthar-waitlist-button ${error ? 'error' : ''}`} 
          onClick={handleWaitlistSignup}
          disabled={loading || error}  // Disable the button when loading
        >
          {loading ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              &nbsp;Submitting...
            </>
          ) : error ? (
            'Invalid Email'  // Show error text
          ) : (
            'Join Waitlist'
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default WaitlistModal;
