import React from 'react';
import { Container } from 'react-bootstrap';

/* TODO: make this path storeHandle/policy and fetch the policy from the backend settings */

function StorePolicy() {
  return (
    <>
      <Container>
        <div className="store-page-wrapper">
          <div className="hint-container warning">
            <p className="hint-container-title">NOTE</p>
            <p>
                I ship these parts in very low volumes (less than 10 a month), I don't carry much inventory.
                If I get your order and I have any in stock, I will ship as soon as possible.
                Otherwise, shipping can take as much as a month.
            </p>
            <p>
                There are significant risks in modifying a CRT and all risks arising from modifying the CRT are your responsibility.
                This kit is sold as is. Returns are not accepted once the kit is used.
            </p>
            <p>
                Make sure to send me your CRT model # to see if I can ship any additional components that can make your life easier.
                Send an email to support@sunthar.com.
                I do this as a hobby, therefore my responses might be delayed.
            </p>
            <p>Once you are done with your mod, you must send me pictures of your mod 😃</p>
            <p>
                As a DIY person, I custom built this shopping cart. It's really basic, but secure as hell.
                https://checkout.sunthar.com is hosted by Stripe. I do not store any of your credit card details.
                Only your purchase, name and shipping info is passed to me by Stripe, so that I can ship and support the product.

                Stripe doesn't support PayPal acceptance in Canada and the US. I will be adding an option to pay using PayPal very soon!
                Stripe offers greater flexibility and more advanced features compared to Paypal.
                Feel free to reach out if you have any questions!
            </p>

            <p>support@sunthar.com</p>

            <p>
                <b>REFUNDS/RETURNS POLICY</b>
            </p>
            <p>
                Several of the parts I ship are customized based on your request. Since I don't run a large operation, 
                a lot of time is invested in packaging and sending the parts. I take best effort to ensure I send you everything, 
                but slight errors can occur. Given the nature of the customization involved, I cannot accept returns or restock items. 
                For example, if couple of resistors or diodes are missing or if incorrect resistor value was shipped, you'll have to source them yourself.
                However, if you are missing a major component that should be part of the kit that is missing. For example, mux board or the ribbon cable, 
                I will take responsibility of it. 
            </p>
            <p>
                If you cancel the order before shipping, there is usually a 5% fee that is not refunded by Stripe. 
                Therefore, you will receive your payment minus the 5% fee or $3 whichever is greater. Items already packaged and/or shipped are not eligible for returns or refunds.
                Thank you for the support and understanding.
            </p>
            <p>
              All packages are insured. Therefore, if the package is damaged or lost due to shipping company's fault, you are covered! 
            </p>
          </div>
        </div>
      </Container>
    </>
  );
}

export default StorePolicy;