// ProductsContext.js
import React, { createContext, useState } from 'react';
import { fetchProductsForStore, fetchStoreFrontProducts, fetchProducts, fetchProduct } from '../services/productService';

// Create the ProductsContext
export const ProductsContext = createContext();

// ProductsProvider component
const ProductsProvider = ({ children }) => {
  const [productsByTypeArray, setProductsByTypeArray] = useState([]);
  const [cartProductsArray, setCartProductsArray] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1); // For pagination handling

  // Function to load products
  const loadStoreFrontProducts = async (storeHandle) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchStoreFrontProducts(storeHandle);

      console.log('Store front products by type: ', response.products_by_type);

      setProductsByTypeArray(response.products_by_type); // Assuming response contains products array

      // Combine all products into a single array
      // This is required as CartContext.js uses this information to add products to cart.
      const combinedProductsArray = Object.values(response.products_by_type).reduce((acc, typeData) => {
        return [...acc, ...typeData.products];
      }, []);

      // Set combined products array in state
      setProductsArray(combinedProductsArray);

    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch products with pagination and optional type filter
  const loadAllProducts = async (storeHandle, type = '', page = 1) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchProductsForStore(storeHandle, type, page);

      console.log('Response from loadAllProducts: ', response);

      setTotalPages(response.total_pages);

      // Check if it's the first page; if so, replace products, otherwise append
      if (page === 1) {
        setProductsArray(response.products);
      } else {
        setProductsArray((prevProducts) => [...prevProducts, ...response.products]);
      }

      // Return the response so it can be handled by the calling component
      return response;
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch a single product
  // Used by the ProductDetails page
  const loadProduct = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchProduct(id);

      console.log ('response: ' + JSON.stringify(response));

      setProduct(response); 

      return response;
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  /*
  // Function to fetch multiple products by an array of IDs
  // Used by the CartModal or any page that requires multiple products at once
  const loadProducts = async (ids) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchProducts(ids);

      console.log ('loadProducts response: ' + JSON.stringify(response));

      // Update the productsArray with the fetched products
      setProductsArray(response);
      return response;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  */

  // Function to fetch multiple products by an array of IDs
  // Used by the CartModal or any page that requires multiple products at once
  const loadCartProducts = async (ids) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchProducts(ids);

      console.log ('loadCartProducts response: ' + JSON.stringify(response));

      // Update the productsArray with the fetched products
      setCartProductsArray(response);
      return response;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to retrieve product data by passing productsArray
  const getProductData = (id) => {
    if (!productsArray) return undefined;

    const productData = productsArray.find((product) => product._id === id) || cartProductsArray.find((product) => product._id === id);
    
    if (!productData) {
      console.error(`Product data does not exist for ID: ${id}`);
      return undefined;
    }
    return productData;
  };

  return (
    <ProductsContext.Provider
      value={{
        product,
        productsArray,
        cartProductsArray,
        productsByTypeArray, // Make sure to use this in the frontend
        loading,
        error,
        totalPages,
        setProduct,
        loadStoreFrontProducts,
        loadCartProducts,
        loadAllProducts,
        //loadProducts,
        loadProduct,
        getProductData
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;