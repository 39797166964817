import {Container} from 'react-bootstrap';

function Cancel() {
    return (
      <Container>
        <div className="store-page-wrapper">
          <p align="center" style={{'margin-top': '60px'}}>
            <h2 className="p-3">
              Sorry to see you cancelled your Stripe payment!
            </h2>
            <a className="sunthar-button" href="/">Back to Super Store</a>
          </p>
        </div>
      </Container>
    )
}

export default Cancel;