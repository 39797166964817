import { createContext, useState, useContext, useEffect } from "react";
import { toast} from 'react-toastify';
import { ProductsContext } from "../context/ProductsContext"; 
import { ShippingContext } from "../context/ShippingContext";

export const CartContext = createContext();

export function CartProvider({children}) {

    const [cartProducts, setCartProducts] = useState(() => {
      // Load cart data from local storage if available
      const storedCart = localStorage.getItem('cart');
      return storedCart ? JSON.parse(storedCart) : [];
    });
    const { getProductData } = useContext(ProductsContext);
    const { getShippingRate, shippingCost } = useContext(ShippingContext);

    // Save cart data to local storage whenever cartProducts changes
    useEffect(() => {
      localStorage.setItem('cart', JSON.stringify(cartProducts));
    }, [cartProducts]);

    function getCartProductQuantity(id) {
      return cartProducts.find(product => product.id === id)?.quantity || 0;
    }

    function formatPrice(cents, currency) {
      if (cents !== undefined && currency) {
        // Convert price to a float (decimal)
        const parsedPrice = parseFloat(cents) / 100;
        
        // If parsedPrice is NaN, return a fallback value
        if (isNaN(parsedPrice)) return 'Invalid price';
      
        const currencyString = currency.toUpperCase();
        
        switch (currencyString) {
          case 'EUR':
            return '€' + parsedPrice.toFixed(2).replace('.', ','); // Use comma for EUR
          case 'GBP':
            return '£' + parsedPrice.toFixed(2); // British pound
          case 'CAD':
            return '$' + parsedPrice.toFixed(2) + ' CAD'; // Default to USD or other currencies
          case 'USD':
            return '$' + parsedPrice.toFixed(2) + ' USD'; // Default to USD or other currencies
          case 'AUD':
            return '$' + parsedPrice.toFixed(2) + ' AUD'; // Default to USD or other currencies
          default:
            return '$' + parsedPrice.toFixed(2); // Default to USD or other currencies
        }
      }
      return;
    }

    function formatPriceShort(cents, currency) {
      if (cents !== undefined && currency) {
        // Convert price to a float (decimal)
        const parsedPrice = parseFloat(cents) / 100;
        
        // If parsedPrice is NaN, return a fallback value
        if (isNaN(parsedPrice)) return 'Invalid price';
      
        const currencyString = currency.toUpperCase();
        
        switch (currencyString) {
          case 'EUR':
            return '€' + parsedPrice.toFixed(2).replace('.', ','); // Use comma for EUR
          case 'GBP':
            return '£' + parsedPrice.toFixed(2); // British pound
          case 'CAD':
            return '$' + parsedPrice.toFixed(2); // Default to USD or other currencies
          case 'USD':
            return '$' + parsedPrice.toFixed(2); // Default to USD or other currencies
          case 'AUD':
            return '$' + parsedPrice.toFixed(2); // Default to USD or other currencies
          default:
            return '$' + parsedPrice.toFixed(2); // Default to USD or other currencies
        }
      }
      return;
    }

    function addOneToCart(id, inventory) {
      const quantity = getCartProductQuantity(id);
      const productData = getProductData(id); 

      if (!productData) {
        console.warn(`Product with ID ${id} not found.`);
        return;
      }

      console.log ('Adding one to Cart. Product Data: ' + JSON.stringify(productData));
      console.log ('Inventory: ' + inventory);

      if (quantity < inventory) {

        if (quantity === 0) { // product is not in cart
          setCartProducts(
              [
                  ...cartProducts,
                  {
                      id: id,
                      price_id: productData.stripe_price_id, //price_id is snake case as it is an api parameter
                      quantity: 1
                  }
              ]
          )
        } else { // product is in cart
          // [ { id: 1 , quantity: 3 }, { id: 2, quantity: 1 } ]    add to product id of 2
          setCartProducts(
              cartProducts.map(
                  product =>
                  product.id === id                                // if condition
                  ? { ...product, quantity: product.quantity + 1 } // if statement is true
                  : product                                        // if statement is false
              )
          )
        }
        toast.success(productData.name + " added to cart", {
          autoClose: 1000
        });
      } else {
        toast.error("Whoa there! You've scooped up all the goodies. There's nothing left to add right now!", {
          autoClose: 2000
        });
        console.log ('Inventory maximum reached!');
      }
    }

    function removeOneFromCart(id) {
        const quantity = getCartProductQuantity(id);

        if(quantity === 1) {
            deleteFromCart(id);
        } else {
            setCartProducts(
                cartProducts.map(
                    product =>
                    product.id === id                                // if condition
                    ? { ...product, quantity: product.quantity - 1 } // if statement is true
                    : product                                        // if statement is false
                )
            )
        }
    }

    function deleteFromCart(id) {
        // [] if an object meets a condition, add the object to array
        // [product1, product2, product3]
        // [product1, product3]
        setCartProducts(
            cartProducts =>
            cartProducts.filter(currentProduct => {
                return currentProduct.id !== id;
            })  
        )
    }

    // Get ShippingCost based on selected country
    function getShippingCost(selectedCountry) {
      //console.log('getShippingCost Selected Country: ', selectedCountry);
      //console.log('getTotalWeight: ', getTotalWeight());
      //console.log('shippingRates', JSON.stringify(shippingRates));

      const shippingRate = getShippingRate(selectedCountry, getTotalWeight());

      //console.log ('shippingRate: ' + JSON.stringify(shippingRate));
 
      return shippingRate && shippingRate.amount ? shippingRate.amount : 0;
    }

    function getTotalWeight() {
      let totalWeight = 0;
    
      cartProducts.forEach(cartItem => {
        const productData = getProductData(cartItem.id); 
        if (!productData) {
          console.warn(`Product with ID ${cartItem.id} not found.`);
          return;
        }

        const productWeight = parseFloat(productData.weight);
        if (!isNaN(productWeight)) {
          totalWeight += (productWeight * cartItem.quantity);
        } else {
          console.warn(`Invalid weight for product with ID: ${cartItem.id}`);
        }
      });

      return totalWeight;
    }

    function getSubTotalCost() {
      let subTotalCost = 0;
    
      cartProducts.forEach(cartItem => {
        const productData = getProductData(cartItem.id); 
        if (!productData) {
          console.warn(`Product with ID ${cartItem.id} not found.`);
          return;
        }

        const productPrice = parseFloat(productData.price);
        if (!isNaN(productPrice)) {
          subTotalCost += (productPrice * cartItem.quantity);
        } else {
          console.warn(`Invalid price for product with ID: ${cartItem.id}`);
        }
      });

      return subTotalCost;
    }

    function getTotalCost() {
      console.log('Getting Total Cost...');
      return getSubTotalCost() + shippingCost;
    }

    const contextValue = {
        items: cartProducts,
        getCartProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getSubTotalCost,
        getShippingCost,
        getTotalCost,
        getTotalWeight,
        formatPrice,
        formatPriceShort
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;
