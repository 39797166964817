const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

export const initiateCheckout = async (cart, rateId, storeHandle, selectedCountry) => {
  try {
    const response = await fetch(`${STORE_BACKEND_URL}/stripe/checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items: cart.items, shipping_rate_id: rateId, store_handle: storeHandle, country: selectedCountry}),
    });

    // Check if the response is OK
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.error || 'Error initiating checkout');
    }
    
    const result = await response.json();

    if (result.url) {
      window.location.assign(result.url); // Forwarding user to Stripe
    }
  } catch (error) {
    console.error('Error during checkout:', error);
    throw error;
  }
};