import React, { createContext, useState } from 'react';
import { fetchShippingRates } from '../services/shippingService';
import countryLookup from '../utils/countryLookup'; 

export const ShippingContext = createContext();

const ShippingProvider = ({ children }) => {
  const [shippingCost, setShippingCost] = useState([]);
  const [shippingRates, setShippingRates] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadShippingRates = async (storeHandle) => {
    try {
      const shippingRates = await fetchShippingRates(storeHandle);
      setShippingRates(shippingRates);

      // Extract available country codes from shippingRates and map them to full country names
      const countryCodes = Object.keys(shippingRates);
      const countries = countryCodes.map(code => ({
        code, // Two-letter country code
        name: countryLookup[code] || code // Full name or fallback to the code if not in lookup
      }));

      console.log ('loadShippingRates countries: ' + JSON.stringify(countries));

      setAvailableCountries(countries); // Save available countries in state
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to get the shipping rate based on country and weight
  const getShippingRate = (country, weight) => {
    if (!shippingRates[country]) {
      console.error(`No shipping rates found for country: ${country}`);
      return null;
    }

    console.log ('Finding Rates for ' + country + ', weight: ' + weight);
    console.log ('ShippingContext getShippingRate: ', shippingRates[country]);

    // Find the appropriate rate based on the weight
    const rate = shippingRates[country].find(
      rate => weight >= rate.min_weight && weight <= rate.max_weight
    );

    if (!rate) {
      console.error(`No shipping rate found for weight: ${weight} in country: ${country}`);
      return null;
    }

    setShippingCost(rate.amount);

    // Return the shipping rate details
    return {
      rateId: rate.rate_id,
      amount: rate.amount,
      currency: rate.currency,
      label: rate.label,
      zone: rate.zone
    };
  };

  return (
    <ShippingContext.Provider value={{
      loading,
      error,
      shippingCost,
      shippingRates,
      availableCountries,
      setShippingCost,
      loadShippingRates,
      getShippingRate
    }}>
      {children}
    </ShippingContext.Provider>
  );
};

export default ShippingProvider;
