const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

export const fetchStoreData = async (storeHandle = 'sunthar') => {
  const url = `${STORE_BACKEND_URL}/stores/public/${storeHandle}`;

  console.log('fetchStoreData called with url: ' + url);
  
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch products: ${response.statusText}`);
    }
    const data = await response.json();
    //console.log('Products Fetched: ' + JSON.stringify(data));

    return data; // Assuming data contains products and totalPages
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};
