import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useContext } from 'react';
import { CartContext } from '../context/CartContext';
import { ProductsContext } from '../context/ProductsContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductDetailsPage.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const ProductDetailsPage = () => {
  const { id } = useParams();
  const { loadProduct, product, setProduct } = useContext(ProductsContext);
  const cart = useContext(CartContext);
  // State to store the current product details
  //const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log ('Product ID: ' + id);
        const product = await loadProduct(id);
        setProduct(product);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      <ToastContainer />
      <div className="product-details-wrapper">
        {product ? (
          <div className="row">
            {/* Main Content */}
            <div className="col-lg-8">
              {/* Product Images with Captions */}
              {product.images && product.images.map((image, idx) => (
                <div key={idx} className="product-image-section">
                  <img src={image.url} alt={`Product Image ${idx + 1}`} className="product-image" />
                  {image.caption && <p className="image-caption">{image.caption}</p>}
                </div>
              ))}
              {/* YouTube Videos */}
              {product.videos && product.videos.map((video, idx) => (
                <div key={idx} className="product-video-wrapper">
                  <div className="product-video-container">
                    <iframe
                      src={`https://www.youtube.com/embed/${video}`}
                      className="product-video"
                      title={`Product Video ${idx + 1}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              ))}
            </div>
            {/* Side Rail */}
            <div className="col-lg-4">
              <div className="product-details-siderail">
                {/* Product Details */}
                <div className="product-details">
                  <h3 className="product-title">{product.name}</h3>
                  <p>
                    <strong>Description:</strong> {product.description}
                  </p>
                  <p className='product-price'>
                    <strong>Price:</strong> {cart.formatPrice(product.price, product.currency)}
                  </p>
                  {(product.type === 'sale' || product.type === 'service') && (
                  <p>
                    <strong>Inventory:</strong> {product.inventory > 0 ? `${product.inventory} in stock` : 'Out of Stock'}
                  </p>
                  )}
                </div>

                {/* Product Actions */}
                {(product.type === 'sale' || product.type === 'service') && (
                  <div className="product-actions">
                    {product.inventory > 0 ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => cart.addOneToCart(product._id, product.inventory)}
                      >
                        Add to Cart
                      </button>
                    ) : (
                      <button className="btn btn-secondary" disabled>
                        Out of Stock
                      </button>
                    )}
                  </div>
                )}

                {product.type === 'affiliate' && product.affiliate_link && (
                  <div className="product-actions">
                    <a href={product.affiliate_link} target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-primary">
                        Shop Now!
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p>Loading product details...</p>
        )}
      </div>
    </Container>
  );

};

export default ProductDetailsPage;
