// productService.js

// With React you have to prefix the environment variable with REACT_APP_
const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

export const fetchStoreFrontProducts = async (storeHandle) => {
  const url = `${STORE_BACKEND_URL}/stores/public/${storeHandle}/products/storefront`;

  console.log('fetchStoreFrontProducts called with url: ' + url);
  
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch products: ${response.statusText}`);
    }
    const data = await response.json();
    //console.log('Products Fetched: ' + JSON.stringify(data));

    return data; // Assuming data contains products and totalPages
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};


export const fetchProductsForStore = async (storeHandle, type = '', page = 1) => {
  const url = `${STORE_BACKEND_URL}/stores/public/${storeHandle}/products?type=${type}&page=${page}`;

  console.log('fetchProductsForStore called with url: ' + url);
  
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch products: ${response.statusText}`);
    }
    const data = await response.json();
    //console.log('Products Fetched: ' + JSON.stringify(data));

    return data; // Assuming data contains products and totalPages
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const fetchProduct = async (id) => {
  const url = `${STORE_BACKEND_URL}/products/${id}`;

  console.log('fetchProduct called with url: ' + url);
  
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch products: ${response.statusText}`);
    }
    const data = await response.json();
    //console.log('Products Fetched: ' + JSON.stringify(data));

    return data; // Assuming data contains products and totalPages
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const fetchProducts = async (ids) => {
  console.log('fetchProducts called with ids: ' + JSON.stringify(ids));
  
  try {

    // POST request to fetch multiple products by their IDs
    const response = await fetch(`${STORE_BACKEND_URL}/products/batch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ productIds: ids }),
    });

    if (!response.ok) {
      throw new Error('Error fetching products');
    }

    const data = await response.json();
    //console.log('Products Fetched: ' + JSON.stringify(data));

    return data; // Assuming data contains products and totalPages
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};





