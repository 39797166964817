import {Container} from 'react-bootstrap';

function Success() {
    return (
      <Container>
        <div className="store-page-wrapper">
          <div align="center" style={{marginTop: '60px'}}>
            <h2 className="p-3">
              Thank you for your purchase!
            </h2>
            <a className="sunthar-button" href="/">Back to Super Store</a>
          </div>
        </div>
      </Container>
    )
}

export default Success;