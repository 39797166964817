import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { ProductsContext } from '../context/ProductsContext';
import { ShippingContext } from "../context/ShippingContext";
import { StoreContext } from '../context/StoreContext';
import ProductCard from '../components/ProductCard';
import WaitlistModal from '../components/WaitlistModal';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function Store() {
  const { storeHandle = 'sunthar'} = useParams(); 
  const { storeData, loading: storeLoading, error: storeError } = useContext(StoreContext);
  const { productsByTypeArray = [], loadStoreFrontProducts } = useContext(ProductsContext);
  const { loadShippingRates } = useContext(ShippingContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log ('Store Handle: ' + storeHandle);
        await loadStoreFrontProducts(storeHandle); // Fetch store front products
        await loadShippingRates(storeHandle); // Load shipping rates
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (storeHandle) {
      fetchData();
    }
  }, [storeHandle]);

  const handleImageClick = (product) => {
    navigate(`product/${product._id}`);
  };

  const handleWaitlistClick = (product) => {
    setSelectedProduct(product);
    setShowWaitlistModal(true);
  };

  const handleWaitlistModalClose = () => {
    setShowWaitlistModal(false);
  };

  if (storeLoading) return <div>Loading...</div>;
  if (storeError) return <div>Error: {storeError}</div>;
  if (loading) return <div>Loading products...</div>;
  if (error) return <div>Error: {error}</div>;

  const localization = storeData?.localization || {};

  // Helper function to render products in rows
  const renderProductRows = (type, typeTitle, products, hasMore) => {
    if (!products || products.length === 0) {
      return null; // Return null to avoid rendering anything if no products are present
    }

    return (
      <div className="type-section">
        <h2 className="product-type-title">{typeTitle}</h2>
        <Row xs={1} md={3} lg={4} className="g-4">
          {products.map((product, idx) => (
            <Col key={idx} align="center">
              <ProductCard
                product={product}
                onImageClick={handleImageClick}
                onWaitlistClick={handleWaitlistClick}
              />
            </Col>
          ))}
        </Row>
        {hasMore && (
          <div className="text-center mt-3">
            <Button onClick={() => navigate(`./${type}`)}>
              View More
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Container>
        <ToastContainer />
        <div className="store-page-wrapper">
          {/* Render Sale Products */}

          {productsByTypeArray.sale && renderProductRows('sale', localization.product_type_sale || 'Featured Products', productsByTypeArray.sale.products, productsByTypeArray.sale.hasMore)}

          {/* Render Showcase Products */}
          {productsByTypeArray.showcase && renderProductRows('showcase', localization.product_type_showcase || 'Showcase', productsByTypeArray.showcase.products, productsByTypeArray.showcase.hasMore)}

          {/* Render Affiliate Products */}
          {productsByTypeArray.affiliate && renderProductRows('affiliate', localization.product_type_affiliate || 'Affiliate Links', productsByTypeArray.affiliate.products, productsByTypeArray.affiliate.hasMore)}

          {/* Waitlist Modal */}
          <WaitlistModal
            show={showWaitlistModal}
            handleClose={handleWaitlistModalClose}
            product={selectedProduct}
            storeHandle={storeHandle}
          />
        </div>
      </Container>
    </>
  );
}

export default Store;
